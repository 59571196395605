/* eslint-disable no-case-declarations */
/* eslint-disable react/jsx-no-constructed-context-values */
/*
 * Author M. Atoar Rahman
 * Title: Center Stock In Context
 * Description: Page for Center Stock In Context
 * Date: 15/03/2022
 */

import { createContext, useContext, useReducer, useState } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';

// Initial State
const initialState = {
    currentStockEntries: [],
};

// Reducer Function
const reducer = (state, action) => {
    switch (action.type) {
        case 'CENTER_STOCK_IN_LIST':
            return {
                ...state,
                currentStockEntries: action.payload,
            };
        case 'ADD_CENTER_STOCK_IN':
            return {
                ...state,
                currentStockEntries: [action.payload, ...state.currentStockEntries],
            };
        case 'EDIT_CENTER_STOCK_IN':
            const updateCurrentStockEntry = action.payload;

            const updateCurrentStockEntrys = state.currentStockEntries.map(
                (currentStockEntries) => {
                    if (currentStockEntries.id === updateCurrentStockEntry.id) {
                        return updateCurrentStockEntry;
                    }
                    return currentStockEntries;
                }
            );
            return {
                ...state,
                currentStockEntries: updateCurrentStockEntrys,
            };

        default:
            return state;
    }
};

// Create Context
const ContextAPI = createContext(initialState);

export function useCurrentStockEntry() {
    return useContext(ContextAPI);
}

// Provider Component
export function CurrentStockEntryProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState);
    // eslint-disable-next-line no-unused-vars
    const [errMsg, setErrMsg] = useState('');
    const [show, setShow] = useState(false);
    const [rowData, setRowData] = useState('');
    const [stockData, setStockData] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false);

    // Dependency
    const axiosPrivate = useAxiosPrivate();

    // API Function: Add/Edit Data
    const addOrUodateData = async (data, id) => {
        setRowData('');
        setIsSubmitted(true);
        try {
            const response = await axiosPrivate.post(
                id
                    ? UrlService.updateCurrentStockEntryUrl(id)
                    : UrlService.saveCurrentStockEntryUrl(),
                JSON.stringify(data),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (response?.data?.status === 'error') {
                setIsSubmitted(false);
                const message = Object.keys(response.data.message);
                message.forEach((key) => {
                    response.data.message[key].map((el) => ToastifyService.dbErrorNotify(el));
                });
                ToastifyService.dbErrorNotify(response?.data?.message);
            } else if (id) {
                ToastifyService.updateNotify('Data Update Successfull');
                setShow(false);
                setIsSubmitted(false);
            } else {
                ToastifyService.saveNotify('Data Create Successfull');
                setShow(false);
                setIsSubmitted(false);
            }
        } catch (err) {
            let err_msg = '';
            console.log(`err`, err?.response);
            if (!err?.response) {
                err_msg = 'Server Not Responding';
                setErrMsg(err_msg);
            } else if (err.response?.status === 401) {
                err_msg = 'Unauthorized';
                setErrMsg(err_msg);
            } else if (id) {
                err_msg = 'Server Not Responding';
                setErrMsg(err_msg);
            } else {
                err_msg = 'Data Insert Failed!';
                setErrMsg(err_msg);
            }
            ToastifyService.errorNotify(err_msg);
        }
    };

    // API Function: Fatch All Data
    const getAllData = async (formData) => {
        try {
            const response = await axiosPrivate.post(
                UrlService.getAllCurrentStockEntryDataUrl(),
                JSON.stringify(formData),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            dispatch({
                type: 'CENTER_STOCK_IN_LIST',
                payload: response?.data.stock_in_info,
            });
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    // Add CurrentStockEntry
    const addCurrentStockEntry = (data) => {
        addOrUodateData(data);
        dispatch({
            type: 'ADD_CENTER_STOCK_IN',
            payload: data,
        });
    };

    // Edit CurrentStockEntry
    const editCurrentStockEntry = (data, id) => {
        addOrUodateData(data, id);
        dispatch({
            type: 'EDIT_CENTER_STOCK_IN',
            payload: data,
        });
    };

    return (
        <ContextAPI.Provider
            value={{
                currentStockEntries: state.currentStockEntries,
                getAllData,
                addCurrentStockEntry,
                editCurrentStockEntry,
                show,
                setShow,
                rowData,
                setRowData,
                stockData,
                setStockData,
                isSubmitted,
                setIsSubmitted,
            }}
        >
            {children}
        </ContextAPI.Provider>
    );
}

export default ContextAPI;
